.main-header {
  /* position: fixed; */
  /* width: 100%; */
  top: 0;
  left: 0;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #741188;
  padding: 0 2rem;
}

.main-header h1 {
  color: white;
}
